import { reject } from 'underscore';
import { ECM_LOGIN_API } from '../apiconfig.js';

// Mock data
export const authenticateUser = (username, password) => {
  return new Promise(resolve => {
    ECM_LOGIN_API.post('api/token/', {"username":username,"password":password})
      .then(response => {
        console.log('ECM Service - Authentication Success :: ', response.data);
        resolve({
          authtoken: response.data
        });
      })
      .catch(error => {
        console.log('ECM Service - Authentication Error :: ', error);
        reject(error);
      });
  });
};
