import React, { Component } from 'react';

// Externals
import PropTypes from 'prop-types';

// Material helpers
import { withStyles } from '@material-ui/core';

// Material components
import { Typography } from '@material-ui/core';

// Component styles
const styles = theme => ({
  root: {
    padding: theme.spacing.unit * 4
  },
  company: {
    marginTop: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit * 0
  },
  developer: {
    position: 'fixed',
    right: '11px'
  }
});

class Footer extends Component {
  render() {
    const { classes } = this.props;

    return (
      <div
        style={{
          position: 'fixed',
          bottom: 0,
          paddingLeft: '11px',
          paddingBottom: '0px'
        }}>
        <Typography className={classes.company} variant="body1">
          &copy; Equidius Capital Management. 2020
        </Typography>
      </div>
    );
  }
}

Footer.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Footer);
