import React, { Component } from 'react';
import { AgGridReact } from '@ag-grid-community/react';
import { ClientSideRowModelModule } from '@ag-grid-community/all-modules';
import '@ag-grid-community/all-modules/dist/styles/ag-grid.css';
import '@ag-grid-community/all-modules/dist/styles/ag-theme-balham.css';
import { CSVLink } from "react-csv";

// Externals
import classNames from 'classnames';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import moment from 'moment';

// Material helpers
import { withStyles, Typography } from '@material-ui/core';

// Shared components
import { Portlet, PortletContent } from 'components';

import Modal from 'react-modal';

// Component styles
import styles from './styles';

import './index.css';
var numeral = require('numeral');
var _ = require('lodash');
Modal.setAppElement('#root');

const headers = [
  { label: "Date of Entry", key: "date_of_entry" },
  { label: "Mutual Fund Name", key: "mutual_fund_id.mutual_fund_name" },
  { label: "Fund Scheme Name", key: "fund_scheme_id.fund_scheme_name" },
  { label: "Company Name", key: "company_id.company_name" },
  { label: "%Portfolio Weight", key: "portfolio_weight" },
  { label: "Position in Holding", key: "position_in_holdings" },
  { label: "Inv Changes", key: "inv_changes" },
  { label: "AUM (Cr.)", key: "fund_scheme_id.aum" },
  { label: "First Bought", key: "first_bought_date" },
  { label: "Number of Shares", key: "no_of_shares" }
];

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)'
  }
};

class DataFeedGrid extends Component {
  state = {
    openModal: false,
    selectedFeedDetails: null,
    marketPrice: null,
    feedGridOptions: {
      defaultColDef: {
        sortable: true,
        filter: true,
        floatingFilter: true,
        resizable: true
      },
      columnDefs: [
        {
          field: 'date_of_entry',
          displayName: 'Date of Entry',
          filter: 'agDateColumnFilter',
          width: '130',
          valueFormatter: function (params) {
            return moment(params.value).format('MMM D, YYYY');
          },
          floatingFilter: true,
          filterParams: {
            comparator: function (filterLocalDateAtMidnight, cellValue) {
              var dateAsString = cellValue;
              var dateParts = dateAsString.split('-');
              var cellDate = new Date(
                Number(dateParts[0]),
                Number(dateParts[1]) - 1,
                Number(dateParts[2])
              );

              if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
                return 0;
              }

              if (cellDate < filterLocalDateAtMidnight) {
                return -1;
              }

              if (cellDate > filterLocalDateAtMidnight) {
                return 1;
              }
            }
          }
        },
        {
          field: 'mutual_fund_id.mutual_fund_name',
          displayName: 'Mutual Fund Name',
          floatingFilter: true
        },
        {
          field: 'fund_scheme_id.fund_scheme_name',
          displayName: 'Fund Scheme Name'
        },
        {
          field: 'company_id.company_name',
          displayName: 'Company Name'
        },
        {
          field: 'portfolio_weight',
          displayName: '%PW',
          filter: 'agNumberColumnFilter',
          width: '80'
        },
        {
          field: 'position_in_holdings',
          displayName: 'PIH',
          filter: 'agNumberColumnFilter',
          width: '80'
        },
        {
          field: 'inv_changes',
          displayName: '%Inv Changes',
          filter: 'agNumberColumnFilter',
          width: '125',
          cellRenderer: function (params) {
            let className = 'positive';
            if (parseFloat(params.value) < 0) className = 'negative';

            return (
              '<span class="inv-change ' +
              className +
              '"> ' +
              ((params.value != null) ? params.value : '') +
              '</span>'
            );
          }
        },
        {
          field: 'fund_scheme_id.aum',
          displayName: 'AUM (Cr.)',
          filter: 'agNumberColumnFilter',
          width: '125',
          cellRenderer: function (params) {
            return (
              '<span"> ' +
              ((params.value != null) ? params.value : '') +
              '</span>'
            );
          }
        },
        {
          field: 'first_bought_date',
          displayName: 'First Bought',
          filter: 'agDateColumnFilter',
          width: '122',
          valueFormatter: function (params) {
            return moment(params.value).format('MMM D, YYYY');
          },
          floatingFilter: true,
          filterParams: {
            comparator: function (filterLocalDateAtMidnight, cellValue) {
              var dateAsString = cellValue;
              var dateParts = dateAsString.split('-');
              var cellDate = new Date(
                Number(dateParts[0]),
                Number(dateParts[1]) - 1,
                Number(dateParts[2])
              );

              if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
                return 0;
              }

              if (cellDate < filterLocalDateAtMidnight) {
                return -1;
              }

              if (cellDate > filterLocalDateAtMidnight) {
                return 1;
              }
            }
          }
        },
        {
          field: 'no_of_shares',
          displayName: 'Number of Shares',
          filter: 'agNumberColumnFilter',
          cellRenderer: function (params) {
            return "<span>" + ((params.value).toString().split('.')[0].replace(/(\d)(?=(\d\d)+\d$)/g, "$1,")) + "</span>";
          },
        }
      ],
      animateRows: true,
      rowData: [],
      onRowDoubleClicked: (params) => {
        console.log("Params ", params)
        let feedData = _.filter(this.props.feeds, eachFeed => eachFeed.mutual_fund_id.id === params.data.mutual_fund_id.id && eachFeed.fund_scheme_id.id === params.data.fund_scheme_id.id && eachFeed.company_id.id === params.data.company_id.id)
        console.log("Params feedData feedData ", feedData)
        this.setState({
          openModal: true,
          selectedFeedDetails: feedData,
          marketPrice: null
        })
      }
    }
  };

  dateFormatter = params => {
    return params.value;
  };

  changeSharesToCrores = (number) => {
    const numberofCrores = number / 10000000;
    const splitDecimals = numberofCrores.toString().split('.');
    return splitDecimals[0].replace(/(\d)(?=(\d\d)+\d$)/g, "$1,") + ((splitDecimals[1]) ? ("." + splitDecimals[1].substr(0, 2) + " Cr.") : ' Cr.');
  }

  autoSizeAll = () => {
    let { feedGridOptions } = this.state;
    var allColumnIds = [];
    feedGridOptions.columnApi.getAllColumns().forEach(function (column) {
      allColumnIds.push(column.colId);
    });

    feedGridOptions.columnApi.autoSizeColumns(allColumnIds);
  };

  closeModal = () => {
    this.setState({
      openModal: false
    })
  }

  componentWillMount = () => {
    setTimeout(() => {
      this.autoSizeAll();
    }, 500);
  };

  handleMarkePrice = () => {
    let mPrice = (document.getElementById('market-price').value) ? parseFloat(document.getElementById('market-price').value) : null;
    this.setState({
      marketPrice: mPrice
    })
  }

  render() {
    const { classes, className } = this.props;
    const { feedGridOptions, openModal, selectedFeedDetails, marketPrice } = this.state;

    const rootClassName = classNames(classes.root, className);
    let orderedFeeds = _.orderBy(this.props.feeds, function (o) { return new moment(o.date_of_entry); }, ['desc']);
    let uniqueData = _.uniqBy(orderedFeeds, v => [v.mutual_fund_id.mutual_fund_name, v.fund_scheme_id.fund_scheme_name, v.company_id.company_name].join());
    let currentHoldingShares = 0;
    if (selectedFeedDetails && selectedFeedDetails.length > 0) {
      _.each(selectedFeedDetails, eachData => {
        if (eachData.feed_type === "S") {
          currentHoldingShares = currentHoldingShares - parseInt(eachData.no_of_shares);
        } else {
          currentHoldingShares = currentHoldingShares + parseInt(eachData.no_of_shares);
        }
      })
    }

    return (
      <Portlet className={rootClassName}>
        <PortletContent noPadding>
          <PerfectScrollbar>
            <CSVLink
              filename={"ecm_feeds.csv"}
              data={this.props.feeds}
              className={"download-excel"}
              headers={headers}>
              <img src="/images/csv.PNG" alt="CSV" style={{ width: "22px" }} />
            </CSVLink>
            <div
              id="feed-grid"
              className="inner-col ag-theme-balham"
              style={{ height: '574px' }}>
              <AgGridReact
                gridOptions={feedGridOptions}
                rowData={uniqueData}
                modules={[ClientSideRowModelModule]}
              />

              <Modal
                isOpen={openModal}
                onRequestClose={this.closeModal}
                style={customStyles}
                contentLabel="Example Modal"
              >
                {
                  (selectedFeedDetails && selectedFeedDetails.length > 0) ? <div>
                    <div className="previous-inv-heading">
                      <Typography className={classes.quoteText} variant="h5">
                        Fund Details of {selectedFeedDetails[0].fund_scheme_id.fund_scheme_name}
                      </Typography>
                    </div>

                    <div className="fund-details">
                      <Typography className={classes.quoteText} variant="h6">
                        {selectedFeedDetails[0].mutual_fund_id.mutual_fund_name}
                      </Typography>
                    </div>

                    {(selectedFeedDetails[0].fund_scheme_id.aum && selectedFeedDetails[0].fund_scheme_id.aum != null) ?
                      <div className="previous-inv-heading">
                        <Typography className={classes.quoteText} variant="h5">
                          AUM: {selectedFeedDetails[0].fund_scheme_id.aum} Cr.
                        </Typography>
                      </div> : ''
                    }

                    <div className="last-closing-price" style={{ height: "31px", marginTop: "5px" }}>
                      <input type="number" id="market-price" style={{ width: "192px", padding: "4px" }} placeholder="Enter Lastday Closing Price" />
                            &nbsp;<button style={{ width: "88px", padding: "4px", fontSize: "14px", cursor: "pointer" }} onClick={this.handleMarkePrice.bind(this)}>Calculate</button>
                    </div>

                    <div className="previous-inv-heading">
                      <Typography className={classes.quoteText} variant="h5">
                        <span className="company-name">{selectedFeedDetails[0].company_id.company_name}</span>
                        <span className="first-bought-date">{moment(selectedFeedDetails[0].first_bought_date).format('MMM D, YYYY')}</span>
                        <br /><span style={{ fontWeight: 'normal' }}>
                          Currently holded Shares: {currentHoldingShares.toString().replace(/(\d)(?=(\d\d)+\d$)/g, "$1,")} </span>
                        <span className="market-value">
                          {(marketPrice != null && currentHoldingShares) ? <b>
                            ({this.changeSharesToCrores(currentHoldingShares * marketPrice)})</b> : ''
                          }
                        </span>
                      </Typography>
                    </div>
                    <div class="feed-details-box pad1-left pad1">
                      {
                        _.orderBy(selectedFeedDetails, function (o) { return new moment(o.Buy_or_Sell_Date); }, ['asc']).map(eachFeedData => {
                          return (
                            <div class="pad20 shadow-box dv-ht80">
                              <div class="colsBox30 txt-center mrg1">
                                <input type="button" className={"reportcount-bt" + ((eachFeedData.feed_type && eachFeedData.feed_type === 'S') ? eachFeedData.feed_type : 'B')} value={((eachFeedData.feed_type && eachFeedData.feed_type === 'S') ? eachFeedData.feed_type : 'B')} title={((eachFeedData.feed_type === "S") ? "Sold " : "Bought ") + "entry posted on " + moment(eachFeedData.date_of_entry).format('MMM D, YYYY')} />
                              </div>
                              <div class="colsBox60 mob-txt-just">
                                <div class="t-b8 line-h4">
                                  <span class="portfolio-weight">
                                    %PW: {eachFeedData.portfolio_weight}
                                  </span>
                                  <span className={"inv-change " + ((parseFloat(eachFeedData.inv_changes) < 0) ? "negative" : "positive")}>{eachFeedData.inv_changes}</span><br />

                                  <span className="no-of-shares">
                                    Shares: {eachFeedData.no_of_shares.split('.')[0].toString().replace(/(\d)(?=(\d\d)+\d$)/g, "$1,")} &nbsp;
                                        </span>
                                  <span className="market-value">
                                    {(marketPrice != null && eachFeedData.no_of_shares) ? <b>
                                      ({this.changeSharesToCrores(eachFeedData.no_of_shares * marketPrice)})</b> : ''
                                    }
                                  </span>

                                  <br />
                                  {moment(eachFeedData.Buy_or_Sell_Date).format('MMM D, YYYY')}
                                </div>
                              </div>
                            </div>
                          )
                        })
                      }
                    </div>
                  </div> : ''
                }
              </Modal>
            </div>
          </PerfectScrollbar>
        </PortletContent>
      </Portlet>
    );
  }
}

DataFeedGrid.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(DataFeedGrid);
