import React, { Component } from 'react';

// Externals
import PropTypes from 'prop-types';

// Material helpers
import { withStyles } from '@material-ui/core';

// Dashboard Layout
import { Dashboard as DashboardLayout } from 'layouts';

// Material components
import { CircularProgress, Typography } from '@material-ui/core';

// Shared services
import { getFeeds } from 'services/feed';

// Custom components
import { DataFeedGrid } from './components';

// Component styles
import styles from './style';

class FeedList extends Component {
  state = {
    isLoading: false,
    feeds: [],
    error: null
  };

  async getFeeds(authtoken, history) {
    try {
      this.setState({ isLoading: true });
      const { feeds } = await getFeeds(authtoken, history);

      this.setState({
        isLoading: false,
        feeds
      });
    } catch (error) {
      this.setState({
        isLoading: false,
        error
      });
    }
  }

  componentDidMount() {
    const { history } = this.props;
    let authtoken = sessionStorage.getItem('authentication', null)
    if(authtoken) {
      this.getFeeds(JSON.parse(authtoken), history);
    } else {
      history.push('/sign-in');
    }
  }

  renderFeeds() {
    const { classes } = this.props;
    const { isLoading, feeds, error } = this.state;

    if (isLoading) {
      return (
        <div className={classes.progressWrapper}>
          <CircularProgress />
        </div>
      );
    }

    if (error) {
      return <Typography variant="h6">{error}</Typography>;
    }

    if (feeds.length === 0) {
      return <Typography variant="h6">
        <div style={{marginLeft: "40%"}}>There are no feed data</div></Typography>;
    }

    return <DataFeedGrid feeds={feeds} />;
  }

  render() {
    const { classes } = this.props;

    return (
      <DashboardLayout title="Feeds">
        <div className={classes.root}>{this.renderFeeds()}</div>
      </DashboardLayout>
    );
  }
}

FeedList.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
};

export default withStyles(styles)(FeedList);
