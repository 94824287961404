import React, { Component, Fragment } from 'react';

// Externals
import classNames from 'classnames';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';

// Material helpers
import { withStyles, withWidth } from '@material-ui/core';

// Custom components
import { Topbar } from './components';

// Component styles
import styles from './styles';

class Dashboard extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { classes, title, children } = this.props;

    return (
      <Fragment>
        <Topbar className={classNames(classes.topbar)} title={title} />
        <main className={classNames(classes.content)}>
          {children}
        </main>
      </Fragment>
    );
  }
}

Dashboard.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  title: PropTypes.string
};

export default compose(withStyles(styles), withWidth())(Dashboard);
