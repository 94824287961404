import { reject } from 'underscore';
import { ECM_API_URL } from '../apiconfig.js';
import Axios from 'axios';

// Mock data
export const getFeeds = (authtoken, history) => {
  return new Promise(resolve => {
    Axios.get(ECM_API_URL + 'ecm/ecmfeeds/', {
      headers: {
        'Authorization': `Bearer ${authtoken.access}` 
      }
    })
      .then(response => {
        console.log('ECM Service - Feeds Fetch Success :: ', response.data);
        resolve({
          feeds: response.data,
          feedsTotal: response.data.length
        });
      })
      .catch(error => {
        console.log('ECM Service - Feeds Fetch Error :: ', error);
        history.push('/sign-in');
        reject(error);
      });
  });
};
