import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';

// Externals
import classNames from 'classnames';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';

// Material helpers
import { withStyles } from '@material-ui/core';

// Material components
import { IconButton, Toolbar, Typography } from '@material-ui/core';

// Material icons
import { Input as InputIcon } from '@material-ui/icons';

// Component styles
import styles from './styles';

class Topbar extends Component {
  handleSignOut = () => {
    const { history } = this.props;

    sessionStorage.setItem('authentication', null);
    history.push('/sign-in');
  };

  render() {
    const { classes, className, title } = this.props;
    const rootClassName = classNames(classes.root, className);

    return (
      <Fragment>
        <div className={rootClassName}>
          <Toolbar className={classes.toolbar}>
            <img
              src="/images/ecm.PNG"
              height="63px"
              alt="Equidius Capital Management"
            />
            <Typography className={classes.title} variant="h4">
              {title}
            </Typography>
            <IconButton
              className={classes.signOutButton}
              onClick={this.handleSignOut}>
              <InputIcon />
            </IconButton>
          </Toolbar>
        </div>
      </Fragment>
    );
  }
}

Topbar.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  title: PropTypes.string
};

export default compose(withRouter, withStyles(styles))(Topbar);
